module.exports = {
    //orange
    //const selectedColor = 'rgb(235,182,23)';

    //pink
    selectedColor: 'rgb(255, 23, 120, .75)',

    selected : {
        backgroundColor: 'rgb(255, 23, 120, .75)',
        color: 'rgb(255,255,255)',
        borderRadius: '12px / 16px',
        padding: '4px',
        transition: 'all 0.4s ease-in',
    },

    defaultStyle: {

    }
}